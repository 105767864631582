import { css, styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { LoadingComponent } from "../molecules/LoadingComponent";
import { showMixinToast, showModalMessage } from "../../utils/Alerts";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { Input } from "../molecules/Input";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { formatPrice, getFullDate } from "../../utils/Utilities";
import { UploadResult, getDownloadURL, getStorage, ref, updateMetadata, uploadString } from "firebase/storage";
import { initialJsonProductProps } from "../../utils/Jsons";
import { startcreateUpdateProductQuery } from "../../queries/ProductQueries";
import { startLoading } from "../../actions/ui";
import { storage } from "../../firebase/firebase-config";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 95%;
    height: 95%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    padding-top: 120px;
    background-color: white;
    overflow-y: scroll;
    white-space: nowrap;
    position: relative;

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 93%;
    max-height: 95%;
    background-color: ${colors.light};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 25px 50px;
    border-bottom: 1px solid ${colors.border};
    position: fixed;
    border-radius: 10px 10px 0px 0px;
    left: 10px;
    right: 10px;
    top: 2.5%;
    margin: auto;
    z-index: 2;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > button {
        margin-top: 0px;
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 20px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 

`

const SectionContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid ${colors.border};

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 30px;
        padding-bottom: 30px;
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

const SectionHeaderContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 10px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
        padding-left: 5px;
        color: ${colors.grey_dark};
    }

    & > button {
        color: ${colors.grey_dark};
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

interface Props {
    data: ProductProps;
    setData: React.Dispatch<React.SetStateAction<ProductProps>>
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setScanProductModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    onConfirm: (product: ProductProps) => void
}

export const NewProductModal = ({ data, setData, setVisible, setScanProductModalVisible, onConfirm }: Props) => {

    const { products, brands, checkingBrands } = useSelector((state: StoreProps) => state.fallherramientas);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [isEdit] = useState<boolean>(data.id !== 0);

    const [imageToUpload, setImageToUpload] = useState<string | null>(null);

    const [form, setForm] = useState<ProductProps>(data);

    const [customProfitForm, setCustomProfitForm] = useState<number | null>(data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? data.price + data.customProfit : null);

    const [showCustomProfit, setShowCustomProfit] = useState<boolean>(form.customProfit !== null && form.customProfit !== undefined && form.customProfit >= 0);

    const inputFileRef: any = useRef(null);

    const inputRef: any = useRef(null);

    const handleClick = () => {
        inputFileRef.current.click();
    };

    const [fieldIncomplete, setFieldIncomplete] = useState({
        brandId: false,
        name: false,
        model: false,
        stockAmount: false,
        price: false,
    })

    useEffect(() => {

        if (!showCustomProfit) {
            setCustomProfitForm(null)
        }

    }, [showCustomProfit])

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            brandId: form.brandId <= 0,
            name: form.name.trim() === '',
            model: form.model.trim() === '',
            stockAmount: form.stockAmount <= 0,
            price: form.price <= 0,
        });

        if (form.brandId <= 0) {
            existError = true;
        }
        if (form.name.trim() === '') {
            existError = true;
        }
        if (form.model.trim() === '') {
            existError = true;
        }
        if (form.stockAmount <= 0 || Number.isNaN(form.stockAmount) || !Number.isInteger(form.stockAmount)) {
            existError = true;
        }
        if (form.customProfit !== null && form.customProfit !== undefined && form.customProfit < 0) {
            existError = true;
        }
        if (form.price <= 0 || Number.isNaN(form.price)) {
            existError = true;
        }

        return existError;

    }

    const handleUpload = async () => {
        if (imageToUpload !== null) {
            const storage = getStorage();
            const imageName = getFullDate();
            const storageRef = ref(storage, `${imageName}`);

            try {
                return await uploadString(storageRef, imageToUpload, 'data_url').then(async (value: UploadResult) => {

                    return await getDownloadURL(value.ref)
                        .then(async (url) => {

                            return url

                        }).catch((e) => {

                            console.log('Error al descargar la URL de imagen : ' + e);

                        });

                });
            } catch (error) {
                alert('Error al subir la imagen');
            }
        }
    };

    const handleFormConfirm = async () => {

        if (customProfitForm !== null && customProfitForm !== undefined && customProfitForm < 0
            || customProfitForm !== null && customProfitForm !== undefined && customProfitForm - form.price < 0) {

            showModalMessage(
                'Ups...',
                `El precio final que indicaste es menor al costo. Estas indicando perdidas.`,
                'warning'
            )

        } else {

            if (!formValidations()) {

                dispatch(startLoading());

                await handleUpload().then(async (r) => {

                    const body: ProductProps = {
                        ...form,
                        image: r ?? form.image,
                        customProfit: data.customProfit !== customProfitForm
                            && customProfitForm !== null && customProfitForm !== undefined
                            && customProfitForm > 0 && customProfitForm - form.price >= 0
                            ? customProfitForm - form.price
                            : null
                    }

                    await startcreateUpdateProductQuery(body, token!, dispatch);

                    showMixinToast(isEdit ? 'Producto actualizado' : 'Nuevo producto creado', 'success')

                    setScanProductModalVisible(false)

                    setVisible(false);

                    setData(initialJsonProductProps);

                    onConfirm({
                        ...body,
                        image: r ?? form.image,
                    })

                })

            } else {

                showModalMessage('Atención', 'Falta completar información.', 'error');

            }

        }


    }

    const [barcode, setBarcode] = useState('');

    const [tienePrefijo, setTienePrefijo] = useState(false);

    const findProduct = async (code: string) => {

        const productFounded = products.find((value) => { return value.barCode === code })

        if (productFounded) {
            console.log('==========');
            console.log('ENCONTRADO');
            console.log('==========');

            setForm({ ...form, barCode: '' });

            showMixinToast('Código ya existente', 'error')


        } else {
            console.log('==========');
            console.log('NOT FOUND');
            console.log('==========');

            setForm({ ...form, barCode: code });

            showMixinToast('Código escaneado', 'success')

        }

    }

    const handleChange = (event: any) => {

        setForm({ ...form, barCode: event.target.value });
        setBarcode(event.target.value)

        if (event.target.value.startsWith('%A%')) {
            setTienePrefijo(true);
        } else {
            setTienePrefijo(false);
        }
    };

    useEffect(() => {

        const timerId = setTimeout(() => {

            if (barcode.includes('%A%') && barcode.includes('%Z%')) {

                console.log('Código de barras: ' + barcode.replace('%A%', '').replace('%Z%', ''));

                const regex = /%A%(.*?)%Z%/;
                const match = barcode.match(regex);

                if (match && match.length > 1) {
                    findProduct(match[1].replace('%A%', '').replace('%Z%', ''));
                }

                setBarcode('')
            }

        }, 200);

        return () => clearTimeout(timerId);
    }, [barcode]);

    useEffect(() => {

        const handleKeyDown = (event: any) => {
            if (/^[%]$/i.test(event.key)) {
                inputRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [tienePrefijo]);

    const getImageComponent = () => {

        if (imageToUpload !== null) {

            return <img src={imageToUpload} alt="" style={{ height: 150, width: 150, marginBottom: 20, objectFit: 'cover' }} />

        } else if (form.image !== null) {

            return <img src={form.image} alt="" style={{ height: 150, width: 150, marginBottom: 20, objectFit: 'cover' }} />

        } else {

            return null

        }

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                {
                    checkingBrands ?

                        (<LoadingComponent />)

                        :

                        (
                            <>

                                <HeaderContainer>

                                    <Typography
                                        size='28px'
                                        bold
                                    >
                                        Nuevo producto
                                    </Typography>

                                    <Button
                                        type="icon"
                                        icon={require('../../images/plus-primary-dark.png')}
                                        onClick={() => {
                                            setVisible(false);

                                            setData(initialJsonProductProps);
                                        }}
                                        style={{ transform: 'rotate(45deg)', zIndex: 2 }}
                                    />

                                </HeaderContainer>

                                {getImageComponent()}

                                <ActionsContainer>

                                    <Button
                                        type="secondary"
                                        text={form.image ? "Cambiar por otra imagen" : "Agregar imagen"}
                                        icon={require('../../images/plus-primary-dark.png')}
                                        style={{ height: 45, marginBottom: 10, marginTop: 10, minWidth: 'fit-content' }}
                                        fullWidth
                                        onClick={() => {

                                            handleClick()

                                        }}
                                    />

                                    <input
                                        ref={inputFileRef}
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {

                                            const file = e.target.files !== null ? e.target.files[0] : undefined;

                                            if (file) {
                                                // Convertir la imagen a base64
                                                const reader = new FileReader();
                                                reader.onloadend = () => {

                                                    if (reader.result) {

                                                        setImageToUpload(reader.result.toString())

                                                        showMixinToast('Imagen seleccionada', 'success')

                                                        inputFileRef.current.value = null;

                                                    }

                                                };
                                                reader.readAsDataURL(file);
                                            }

                                        }}
                                    />

                                    {
                                        form.image !== null && (
                                            <Button
                                                type="secondary"
                                                text={"Descartar imagen"}
                                                icon={require('../../images/delete-primary-dark.png')}
                                                style={{ height: 45, marginBottom: 10, marginTop: 10, minWidth: 'fit-content' }}
                                                onClick={() => {

                                                    inputRef.current.value = null;

                                                    setForm({
                                                        ...form,
                                                        image: null
                                                    })

                                                    showMixinToast('Imagen descartada', 'info')
                                                }}
                                            />

                                        )
                                    }

                                </ActionsContainer>

                                <SubContainer>

                                    <Input
                                        label="Marca"
                                        type="select"
                                        value={form.brandId}
                                        theme="dark"
                                        incomplete={fieldIncomplete.brandId}
                                        onChange={(e) => {

                                            setForm({ ...form, brandId: parseInt(e.target.value) });
                                        }}
                                    >
                                        <option value={0}>SELECCIONE UNA MARCA</option>
                                        {
                                            !checkingBrands && brands.map((brand) => {

                                                return <option value={brand.id}>{brand.name}</option>
                                            })
                                        }
                                    </Input>

                                </SubContainer>

                                <SubContainer>

                                    <Input
                                        type="text"
                                        label="Nombre"
                                        value={form.name}
                                        placeholder="Nombre del producto"
                                        incomplete={fieldIncomplete.name}
                                        onChange={(e) => {

                                            setFieldIncomplete({ ...fieldIncomplete, name: false });

                                            setForm({
                                                ...form,
                                                name: e.currentTarget.value
                                            })
                                        }}
                                    />

                                    <Input
                                        type="text"
                                        label="Modelo"
                                        value={form.model}
                                        placeholder="Modelo del producto"
                                        incomplete={fieldIncomplete.model}
                                        onChange={(e) => {

                                            setFieldIncomplete({ ...fieldIncomplete, model: false });

                                            setForm({
                                                ...form,
                                                model: e.currentTarget.value
                                            })
                                        }}
                                    />

                                </SubContainer>

                                <SubContainer>

                                    <Input
                                        type="number"
                                        label="Cantidad disponible en stock"
                                        value={form.stockAmount > 0 ? form.stockAmount : ''}
                                        placeholder="Cantidad de productos"
                                        incomplete={fieldIncomplete.stockAmount}
                                        onChange={(e) => {

                                            if (/^[0-9]*$/.test(e.target.value)) {

                                                setFieldIncomplete({ ...fieldIncomplete, stockAmount: false });

                                                setForm({
                                                    ...form,
                                                    stockAmount: e.target.value.trim() === '' ? 0 : parseInt(e.currentTarget.value)
                                                })

                                            }
                                        }}
                                    />

                                    <Input
                                        type="number"
                                        label={`Precio de costo ($${formatPrice(!Number.isNaN(form.price) ? form.price : 0)})`}
                                        value={form.price === 0 || Number.isNaN(form.price) ? '' : form.price}
                                        placeholder="Precio de costo del producto"
                                        incomplete={fieldIncomplete.price}
                                        onChange={(e) => {

                                            // if (/^\d+$/.test(e.target.value)) {

                                            setFieldIncomplete({ ...fieldIncomplete, price: false });

                                            setForm({
                                                ...form,
                                                price: parseFloat(e.target.value)
                                            })

                                            // }

                                        }}
                                    />


                                </SubContainer>

                                {/* <SectionContainer
                                    fieldincomplete=""
                                    style={{ paddingBottom: 30, marginBottom: 15 }}
                                >

                                    <SectionHeaderContainer fieldincomplete={''}>

                                        <Typography size="24px">
                                            Ganancia del producto
                                        </Typography>

                                    </SectionHeaderContainer>

                                    <SubContainer>

                                        <Typography
                                            size='14px'
                                            bold
                                            style={{
                                                border: `1px solid ${colors.primary_light}`,
                                                backgroundColor: colors.light,
                                                color: colors.primary,
                                                padding: 15,
                                                textAlign: 'center',
                                                borderRadius: 5,
                                                marginTop: 30,
                                                width: '100%',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            Si indicás manualmente el precio final de tu producto, se va a calcular automáticamente la ganancia.
                                        </Typography>

                                    </SubContainer>

                                    <Checkbox
                                        style={{ width: '97.5%', marginTop: 30, marginBottom: 10 }}
                                        type="switch"
                                        text="¿Querés indicar manualmente el precio final?"
                                        checked={showCustomProfit}
                                        onClick={() => setShowCustomProfit(!showCustomProfit)}
                                    />

                                    {
                                        showCustomProfit && (

                                            <Input
                                                type="number"
                                                label={`Precio final ($${formatPrice(customProfitForm !== null && customProfitForm !== undefined && !Number.isNaN(customProfitForm) ? customProfitForm : 0)})`}
                                                value={customProfitForm !== null && customProfitForm !== undefined && customProfitForm === 0 || Number.isNaN(customProfitForm) ? '' : customProfitForm}
                                                placeholder="Precio final del producto"
                                                onChange={(e) => {

                                                    // setForm({
                                                    //     ...form,
                                                    //     customProfit: parseFloat(e.target.value)
                                                    // })

                                                    setCustomProfitForm(parseFloat(e.target.value));

                                                }}
                                            />

                                        )
                                    }

                                </SectionContainer> */}


                                <SectionContainer
                                    fieldincomplete=""
                                    style={{ paddingBottom: 30, marginBottom: 15 }}
                                >

                                    <SectionHeaderContainer fieldincomplete={''}>

                                        <Typography size="24px">
                                            Código de barras
                                        </Typography>

                                    </SectionHeaderContainer>

                                    <SubContainer>

                                        <Typography
                                            size='14px'
                                            bold
                                            style={{
                                                border: `1px solid ${colors.primary_light}`,
                                                backgroundColor: colors.primary,
                                                color: colors.light,
                                                padding: 15,
                                                textAlign: 'center',
                                                borderRadius: 5,
                                                marginTop: 30,
                                                width: '100%',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            Use el lector y escanee el código de barras del producto
                                        </Typography>

                                    </SubContainer>


                                    <Input
                                        reference={inputRef}
                                        type="text"
                                        label="Código de barras"
                                        value={form.barCode}
                                        placeholder="Ingrese manualmente el código"
                                        onChange={(e) => {

                                            handleChange(e);
                                        }}
                                    />


                                </SectionContainer>

                                <FooterButtonsContainer
                                    style={{ marginTop: 50 }}
                                    primaryButtonText={
                                        form.brandId <= 0 ||
                                            form.name.trim() === '' ||
                                            form.model.trim() === '' ||
                                            form.stockAmount <= 0 ||
                                            (customProfitForm !== null && customProfitForm !== undefined && customProfitForm < 0) ||
                                            form.price <= 0 ?
                                            'Faltan ingresar datos'
                                            :
                                            isEdit ? 'Editar producto' : 'Crear nuevo producto'
                                    }
                                    secondaryButtonText={'Cancelar'}
                                    primaryDisabled={
                                        form.brandId <= 0 ||
                                        form.name.trim() === '' ||
                                        form.model.trim() === '' ||
                                        form.stockAmount <= 0 ||
                                        (customProfitForm !== null && customProfitForm !== undefined && customProfitForm < 0) ||
                                        form.price <= 0
                                    }
                                    secondaryDisabled={false}
                                    onClickPrimary={() => { handleFormConfirm() }}
                                    onClickSecondary={() => {
                                        setVisible(false);

                                        setData(initialJsonProductProps);
                                    }}
                                />


                            </>
                        )

                }



            </ModalCardContainer>

        </ShadowContainer>

    )
}
